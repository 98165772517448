import { getMetaDescription, getPreviewImage } from "@/util/note"

import BlogNoteIdPageComponent, { GetLayout } from "../[...noteId]"

import { captureException } from "@sentry/nextjs"

import { setCurrentNoteId, setAllNotes } from "features/noteSlice"

import { getBlogByUrl } from "features/blogSlice"

import {
  getPostGatingRuleGroups,
  setPostGatingRuleGroups,
} from "features/gatingRuleGroupsSlice"

import { wrapper } from "store"
import { isValidBlog } from "@/util/format"
import { getPublishedNote } from "api_routes/published"
import { GetServerSideProps } from "next"

export const getServerSideProps: GetServerSideProps =
  wrapper.getServerSideProps((store) => async ({ params }) => {
    try {
      console.log("Loading getServerSideProps BlogNoteIdPageComponent")

      console.log("Beginning to run noteId getServerSideProps")
      const blogName = params?.blogname as string
      const noteId = params?.noteId as string

      if (!isValidBlog(blogName)) {
        console.error("Invalid blog name, so returning 404!", blogName, params)
        return {
          notFound: true,
        }
      }

      const promises: any[] = []

      if (noteId) {
        promises.push(store.dispatch(setCurrentNoteId(noteId)))
      }

      console.log("GETTING THE USERS BLOG BY URL!" + blogName)

      const blogPromise = store.dispatch(getBlogByUrl(blogName))
      promises.push(blogPromise)

      const notePromise = getPublishedNote(blogName, noteId, true)
      promises.push(notePromise)

      console.log(" Awaiting for all promises...")
      await Promise.all(promises)
      const [note, blog] = await Promise.all([notePromise, blogPromise])

      console.log(" Got all promises!")
      console.log(" Got the published note, neat!", blogName, noteId)

      if (!note || !blog.id) {
        console.error("Note not found, so returning 404!", note, blog)
        return {
          notFound: true,
        }
      }

      // Retrieve gating rules for this post. Can't execute this promise until the above are done as we need the actual note unique ID and not just the slug.
      // This preloads the data from the server-side and makes it available to the PostSkeleton component to later figure out if the user has access or not.
      console.log(
        "GatingRules: Queueing promise to retrieve current post's gating rule groups for a reader."
      )
      const gatingRuleGroups = await store.dispatch(
        getPostGatingRuleGroups(note.id)
      )

      if (gatingRuleGroups) {
        // Then update the local state.
        store.dispatch(setPostGatingRuleGroups(gatingRuleGroups))
      }

      // On client-side, we'll fetch it to check ACLs.
      promises.push(store.dispatch(setAllNotes([note])))

      const description = getMetaDescription({ note, blog }) || null
      const previewImage = getPreviewImage(note) || blog.logo_url || null

      return {
        props: { description, previewImage },
        notFound: false,
      }
    } catch (error) {
      console.error("Error in getStaticProps for noteId", error)
      captureException(error)

      return {
        redirect: {
          destination: "/500",
          permanent: false,
        },
      }
    }
  })

type Props = { description: string; previewImage: string }

export default function Preview({ description, previewImage }: Props) {
  return (
    <BlogNoteIdPageComponent
      description={description}
      previewImage={previewImage}
      preview={true}
      collectible={{}}
    />
  )
}

Preview.getLayout = GetLayout
